import React from 'react';
import PropTypes from 'prop-types';
import Popover from '../../../../shared/Popover';
import styles from './DownloadButton.scss';

class DownloadButton extends React.Component {
  renderList = () => {
    const { downloadItems } = this.props;

    return (
      <ul className={styles.list}>
        {downloadItems.map((item, index) => (
          <li key={index} onClick={() => this.downloadFile(item)}>
            <a>{item.title}</a>
          </li>
        ))}
      </ul>
    );
  };

  downloadFile = ({ filename, url }) => {
    window
      .fetch(url)
      .then(r => r.blob())
      .then(blob => {
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const element = document.createElement('a');

          document.body.appendChild(element);
          element.setAttribute('href', window.URL.createObjectURL(blob));
          element.setAttribute('download', filename);
          element.style.display = '';

          element.click();

          document.body.removeChild(element);
        }
        if (this.popoverActions) {
          this.popoverActions.close();
        }
      });
  };

  render() {
    return (
      <Popover
        actions={actions => (this.popoverActions = actions)}
        content={this.renderList()}
      >
        <div className={styles.wrapper} />
      </Popover>
    );
  }
}

DownloadButton.propTypes = {
  downloadItems: PropTypes.array.isRequired
};

export default DownloadButton;
