import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ChevronCircle from '../../../ChevronCircle';
import styles from './MobileTableListItem.scss';

class MobileTableListItem extends React.Component {
  state = {
    open: false
  };

  toggleContent = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { open } = this.state;
    const { row, columns, leadingColumn } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.title} onClick={this.toggleContent}>
          <ChevronCircle
            className={styles.chevron}
            direction={open ? 'top' : 'bottom'}
          />
          {row[columns[leadingColumn].accessor]}
        </div>
        <div
          className={classnames(styles.content, {
            [styles.open]: this.state.open
          })}
        >
          {columns.map(
            (column, index) =>
              index !== leadingColumn && (
                <div key={index} className={styles.column}>
                  <div className={styles.columnName}>{column.name}</div>
                  <div className={styles.columnValue}>
                    {row[column.accessor]}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    );
  }
}

MobileTableListItem.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  leadingColumn: PropTypes.number
};

export default MobileTableListItem;
