import React from 'react';
import PropTypes from 'prop-types';
import HTTPSnippet from 'httpsnippet';
import jwtDecode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Button from '../Button';
import Code from '../Code';
import { _fetch } from '../../../actions/api.config';
import Loader from '../../shared/Loader';
import ToastContainer from '../../shared/ToastContainer';
import TextField from '../TextField';
import styles from './TokensWidget.scss';

class TokensWidget extends React.Component {
  state = {
    isFetching: true,
    user: this.props.users[0].id,
    userData: null,
    isRenewing: false
  };

  componentDidMount() {
    this.getUserData();
  }

  getUserData = () => {
    _fetch('/tpp/psu')
      .then(userData => {
        userData = this.getCorrectUsersData(userData);
        this.setState({ isFetching: false, userData });
      })
      .catch(error => {
        ToastContainer.show(error.message || error);
      });
  };

  // Will get selected user from a given json.
  getCorrectUsersData (userData) {
    for (const i in userData) {
      const user = userData[i]

      if (user.id === this.state.user) {
        return user;
      }
    }
    return null;
  }

  renewToken = () => {
    this.setState(
      {
        isRenewing: true
      },
      () => {
        _fetch('/tpp/psu')
          .then(userData => {
            userData = this.getCorrectUsersData(userData);
            this.setState({ userData });
          })
          .catch(error => {
            ToastContainer.show(error.message || error);
          })
          .finally(() => {
            this.setState({ isRenewing: false });
          });
      }
    );
  };

  changeUser = ({ target: { value } }) => {
    this.setState(
      {
        user: value,
        isFetching: true
      },
      () => {
        this.getUserData();
      }
    );
  };

  decodeJwt = jwt => {
    try {
      const decoded = jwtDecode(jwt);

      return decoded;
    } catch (err) {
      return null;
    }
  };

  render() {
    const { isFetching, user, userData, isRenewing } = this.state;
    const { users, codeSnippetUri } = this.props;

    if (isFetching || !userData) {
      return <Loader />;
    }

    const tokenExpireDate = moment
      .unix(userData.expires_in)
      .format('MMM D, YYYY, HH:mm:ss A');

    const curlSnippet = new HTTPSnippet({
      url: codeSnippetUri,
      method: 'GET',
      headers: [
        {
          name: 'Accept',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${userData.access_token}`
        }
      ]
    });

    return (
      <div className={styles.wrapper}>
        <div className={styles.user}>
          <TextField
            label="Customer"
            value={user}
            options={users.map(user => ({
              value: user.id,
              label: user.userName
            }))}
            onChange={this.changeUser}
          />
        </div>
        <div className={styles.token}>
          <h4>Access token</h4>
          <div className={styles.code}>
            <Code language="json">{userData.access_token}</Code>
          </div>
          <div className={styles.expiration}>
            Access Token expiration date: {tokenExpireDate}
          </div>
        </div>
        <div className={styles.example}>
          <h4>Example of adding tokens into headers</h4>
          <div className={styles.code}>
            <Code language="bash">{curlSnippet.convert('shell')}</Code>
          </div>
        </div>
        <div className={styles.button}>
          <Button onClick={this.renewToken} disabled={isRenewing}>
            Renew Tokens
          </Button>
        </div>
      </div>
    );
  }
}

TokensWidget.propTypes = {
  uri: PropTypes.string,
  title: PropTypes.string,
  history: PropTypes.object,
  users: PropTypes.array,
  codeSnippetUri: PropTypes.string
};

export default withRouter(TokensWidget);
