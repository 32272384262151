import React from 'react';
import PropTypes from 'prop-types';
import TextToReact from '../TextToReact';
import styles from './Text.scss';

const Text = ({ children }) => (
  <div className={styles.text}>
    <TextToReact>{children}</TextToReact>
  </div>
);

Text.propTypes = {
  children: PropTypes.node
};

export default Text;
