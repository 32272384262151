import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tab.scss';

const Tab = ({ children }) => <div className={styles.wrapper}>{children}</div>;

Tab.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['default', 'success', 'error'])
};

Tab.defaultProps = {
  type: 'default'
};

export default Tab;
