import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.scss';

const Button = ({
  onClick,
  children,
  color,
  className,
  fillWidth,
  fluid,
  type = 'button',
  ...rest
}) => (
  <button
    onClick={onClick}
    type={type}
    className={classnames(className, styles.button, styles[color], {
      [styles.fillWidth]: fillWidth,
      [styles.fluid]: fluid
    })}
    {...rest}
  >
    {children}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'blue']),
  className: PropTypes.string,
  fillWidth: PropTypes.bool,
  type: PropTypes.string
};

Button.defaultProps = {
  color: 'primary'
};

export default Button;
