import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Container from '../Container';
import styles from './Breadcrumbs.scss';

const Breadcrumbs = ({ list, currentUrl }) => (
// const Breadcrumbs = () => (
  <Container wide={true}>
    <div className={styles.breadcrumbs}>
      <ul>
        {list.map(link => (
          <li
            key={link.url}
            className={cx({
              [styles.current]: currentUrl === link.url
            })}
          >
            <Link to={link.url}>{link.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  </Container>
);

Breadcrumbs.propTypes = {
  list: PropTypes.array.isRequired,
  currentUrl: PropTypes.string
};

export default Breadcrumbs;
