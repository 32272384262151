import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { _fetch } from '../../actions/api.config';
import PageResolver from '../pages/PageResolver';
import CookieBar from '../shared/CookieBar';
import Header from '../shared/Header';
import ToastContainer from '../shared/ToastContainer';
import Loader from '../shared/Loader';
import AuthModal from '../modals/AuthModal';
import VerifyModal from '../modals/VerifyModal';
import ForgotPasswordModal from '../modals/ForgotPasswordModal';
import PasswordResetModal from '../modals/PasswordResetModal';
import Logout from '../shared/Logout';
import OverlaysProvider, { OverlaysConsumer } from './Overlays.context';
import AuthProvider, { AuthConsumer } from './Auth.context';

class App extends React.Component {
  state = {
    isFetching: true,
    mainMenu: [],
    userMenu: [],
    cookieBar: {},
    terms: null,
    showCookieBar: !localStorage.getItem('t&c'),
    isLoginOpen: false,
    customers: [],
    socialAppsData: null
  };

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = () => {
    _fetch('initial_data').then(
      ({
        menu_items,
        user_menu,
        cookie_bar,
        terms,
        customers,
        social_auth_apps_ids
      }) => {
        this.setState({
          isFetching: false,
          mainMenu: menu_items,
          userMenu: user_menu,
          cookieBar: cookie_bar,
          terms: terms.field_media_file,
          customers,
          socialAppsData: social_auth_apps_ids
        });
      }
    );
  };

  render() {
    const {
      isFetching,
      mainMenu,
      userMenu,
      cookieBar,
      showCookieBar,
      terms,
      customers,
      socialAppsData
    } = this.state;
    const { pathname } = this.props.location;

    if (pathname === '/logout') {
      return (
        <AuthProvider>
          <AuthConsumer>
            {({ onLogOut }) => (
              <Logout logout={onLogOut} onLoggedOut={this.getInitialData} />
            )}
          </AuthConsumer>
        </AuthProvider>
      );
    }

    if (isFetching) {
      return <Loader />;
    }

    return (
      <AuthProvider>
        <OverlaysProvider>
          <Header navLinks={mainMenu} userMenu={userMenu} />

          {showCookieBar && (
            <CookieBar
              message={cookieBar.message}
              onAgree={() => {
                this.setState(
                  {
                    showCookieBar: false
                  },
                  () => {
                    localStorage.setItem('t&c', true);
                  }
                );
              }}
            />
          )}

          <OverlaysConsumer>
            {({ toggleOverlay, isOverlayOpen }) => (
              <>
                <AuthConsumer>
                  {({ isAuthorized, onLogin }) => (
                    <>
                      <AuthModal
                        onLogin={onLogin}
                        isOpen={
                          (isOverlayOpen('login') ||
                            isOverlayOpen('register')) &&
                          !isAuthorized
                        }
                        toggleOverlay={toggleOverlay}
                        terms={terms}
                        authType={(() => {
                          if (isOverlayOpen('login')) {
                            return 'login';
                          } else if (isOverlayOpen('register')) {
                            return 'register';
                          } else {
                            return null;
                          }
                        })()}
                        socialAppsData={socialAppsData}
                      />
                      <ForgotPasswordModal
                        isOpen={
                          isOverlayOpen('forgotPassword') && !isAuthorized
                        }
                      />
                      {isOverlayOpen('passwordReset') && !isAuthorized && (
                        <PasswordResetModal onLogin={onLogin} isOpen={true} />
                      )}
                      {isOverlayOpen('emailVerify') && (
                        <VerifyModal onLogin={onLogin} />
                      )}
                    </>
                  )}
                </AuthConsumer>
              </>
            )}
          </OverlaysConsumer>

          <ToastContainer />

          <Switch>
            <Route
              path="*"
              key={pathname}
              render={props => (
                <PageResolver
                  pathname={pathname}
                  customers={customers}
                  {...props}
                />
              )}
            />
          </Switch>
        </OverlaysProvider>
      </AuthProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object.isRequired,
  history: PropTypes.object
};

export default hot(module)(withRouter(App));
