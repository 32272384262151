import React from 'react';
import PropTypes from 'prop-types';
import styles from './VideoIframe.scss';

const VideoIframe = ({ src }) => (
  <div className={styles.iframe}>
    <iframe src={src} frameBorder="0" />
  </div>
);

VideoIframe.propTypes = {
  src: PropTypes.string.isRequired
};

export default VideoIframe;
