import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const AuthContext = React.createContext({});

class AuthProvider extends React.Component {
  logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_name');
    this.props.history.push({
      pathname: this.props.history.location.pathname
    });
  };

  login = ({ access_token, name }, keepOverlay = false) => {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('user_name', name);

    if (keepOverlay) {
      this.forceUpdate();
    } else {
      this.props.history.replace({
        pathname: this.props.history.location.pathname
      });
    }
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthorized: !!localStorage.getItem('access_token'),
          accessToken: localStorage.getItem('access_token'),
          userName: localStorage.getItem('user_name'),
          onLogOut: this.logout,
          onLogin: this.login
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(AuthProvider);
export const AuthConsumer = AuthContext.Consumer;
