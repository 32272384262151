import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MediaQuery from 'react-responsive';
import { MOBILE_BREAKPOINT } from '../../../../constants/breakpoints';
import { OverlaysConsumer } from '../../../App/Overlays.context';
import StickyAuthButtons from '../../../shared/Header/StickyAuthButtons';
import Button from '../../../shared/Button';
import bgImage from '../../../../assets/icons/usermenu-graphics.svg';
import styles from './UserMenu.scss';

const UserMenu = ({
  isOpen,
  navLinks,
  onClose,
  isAuthorized,
  userName,
  onLogOut
}) => (
  <Fragment>
    {isAuthorized && (
      <div
        className={cx(styles.userMenu, {
          [styles.open]: isOpen
        })}
        style={{
          backgroundImage: `url(${bgImage})`
        }}
      >
        <div className={styles.top}>
          {isAuthorized && (
            <div className={styles.userDetails}>
              <div className={styles.userAvatar}>
                {userName.substring(0, 2)}
              </div>
              <div className={styles.userName}>{userName}</div>
            </div>
          )}
        </div>
        <div className={styles.center}>
          <ul className={styles.nav}>
            {navLinks.map(link => (
              <li key={link.title + link.relative}>
                <NavLink
                  to={link.relative}
                  exact
                  activeClassName={styles.active}
                >
                  {link.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.bottom}>
          {isAuthorized ? (
            <div className={styles.buttonHolder}>
              <Button onClick={onLogOut} color="blue">
                LOGOUT
              </Button>
            </div>
          ) : (
            <MediaQuery maxWidth={MOBILE_BREAKPOINT}>
              <OverlaysConsumer>
                {({ toggleOverlay }) => (
                  <div className={styles.buttonHolder}>
                    <StickyAuthButtons toggleOverlay={toggleOverlay} />
                  </div>
                )}
              </OverlaysConsumer>
            </MediaQuery>
          )}
        </div>
      </div>
    )}
    {isOpen && <div className={styles.backdrop} onClick={onClose} />}
  </Fragment>
);

UserMenu.propTypes = {
  isOpen: PropTypes.bool,
  navLinks: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  onLogOut: PropTypes.func
};

export default UserMenu;
