import React from 'react';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import { map } from 'lodash';
import Code from '../Code';
import VideoIframe from '../VideoIframe';
import Table from '../Table';

const TextToReact = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <>
      {Parser(children, {
        replace: function(domNode) {
          if (
            domNode.name === 'a' &&
            domNode.attribs &&
            domNode.attribs.href[0] === '/'
          ) {
            return React.createElement(
              Link,
              {
                to: domNode.attribs.href
              },
              domNode.children.length ? domNode.children[0].data : ''
            );
          }

          if (
            domNode.name === 'code' &&
            domNode.children &&
            domNode.children.length &&
            domNode.children[0].data &&
            domNode.attribs &&
            domNode.attribs.class &&
            domNode.attribs.class.includes('language-')
          ) {
            const language = domNode.attribs.class.replace('language-', '');
            const content = domNode.children[0].data;

            return React.createElement(
              Code,
              {
                language
              },
              content
            );
          }

          if (
            domNode.name === 'iframe' &&
            domNode.attribs &&
            domNode.attribs.src
          ) {
            return React.createElement(VideoIframe, {
              src: domNode.attribs.src
            });
          }

          if (domNode.name === 'table') {
            const extract = (type, array, i = 0, r = []) => {
              const index = i;
              const results = r;

              array.forEach(el => {
                if (index === type.length - 1) {
                  results.push(el.data);
                } else if (
                  (el.type === type[index] || el.name === type[index]) &&
                  el.children &&
                  el.children.length
                ) {
                  extract(type, el.children, index + 1, results);
                }
              });

              return results;
            };

            const thead = extract(
              ['thead', 'tr', 'th', 'text'],
              domNode.children
            );

            const tbody = extract(
              ['tbody', 'tr', 'td', 'text'],
              domNode.children
            );

            return React.createElement(Table, {
              columns: thead.map((t, i) => ({ name: t, accessor: i })),
              data: map(tbody, (item, index) =>
                index % thead.length === 0
                  ? tbody.slice(index, index + thead.length)
                  : null
              ).filter(item => item)
            });
          }
        }
      })}
    </>
  );
};

export default TextToReact;
