import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Highlight, { defaultProps } from 'prism-react-renderer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyIcon from '../../../assets/icons/copy-icon.svg';
import tickIcon from '../../../assets/icons/tick-icon.svg';
import theme from './theme';
import styles from './Code.scss';

class Code extends React.Component {
  state = {
    copied: false
  };

  copiedStateTimer;

  getButtonConfig = () => {
    switch (this.state.copied) {
      case true:
        return {
          icon: tickIcon,
          text: 'Copied'
        };
      default:
        return {
          icon: copyIcon,
          text: 'Copy'
        };
    }
  };

  onCopy = () => {
    clearTimeout(this.copiedStateTimer);

    this.setState({ copied: true }, () => {
      this.copiedStateTimer = setTimeout(() => {
        this.setState({ copied: false });
      }, 3000);
    });
  };

  render() {
    const { children, language } = this.props;

    return (
      <div className={styles.code}>
        <CopyToClipboard text={children} onCopy={this.onCopy}>
          <div className={styles.copyButton} onClick={this.copySourceCode}>
            <div className={styles.content}>
              <div className={styles.iconHolder}>
                <img
                  src={this.getButtonConfig().icon}
                  className={styles.initial}
                  alt=""
                />
              </div>
              <span>{this.getButtonConfig().text}</span>
            </div>
          </div>
        </CopyToClipboard>

        <Highlight
          {...defaultProps}
          code={children}
          language={language}
          theme={theme}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={cx(className, styles.codeWrapper)} style={style}>
              <div className={styles.lines}>
                {tokens.map((line, i) => (
                  <div className={styles.lineIndex} key={i}>
                    {i + 1}
                  </div>
                ))}
              </div>
              <div className={styles.codeSource}>
                {tokens.map((line, i) => (
                  <div {...getLineProps({ line, key: i })} key={i}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} key={key} />
                    ))}
                  </div>
                ))}
              </div>
            </pre>
          )}
        </Highlight>
      </div>
    );
  }
}

Code.propTypes = {
  children: PropTypes.node,
  language: PropTypes.string
};

export default Code;
