import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TextToReact from '../../../shared/TextToReact';
import styles from './FeaturesList.scss';

const FeaturesList = ({ list }) => (
  <div className={styles.featuresList}>
    <div className={styles.container}>
      {list.map((feature, i) => {
        const {
          field_description: description,
          field_title: title,
          field_image: image,
          field_image_position: position
        } = feature;

        return (
          <div
            className={cx(styles.listItem, {
              [styles.right]: position === 'right'
            })}
            key={i}
          >
            <div className={styles.side}>
              <div className={styles.image}>
                {Boolean(image && image.url) && (
                  <img src={image.url} alt={image.alt} />
                )}
              </div>
            </div>
            <div className={styles.side}>
              <div className={styles.text}>
                <h2>{title}</h2>
                <div>
                  {description && <TextToReact>{description}</TextToReact>}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

FeaturesList.propTypes = {
  list: PropTypes.array
};

export default FeaturesList;
