import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './DesktopTable.scss';

const DesktopTable = ({ columns, data, type }) => (
  <table className={classnames(styles.wrapper, styles[`type-${type}`])}>
    <thead>
      <tr>
        {columns.map((column, index) => (
          <th key={index}>{column.name}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          {columns.map((column, yandex) => (
            <td key={yandex}>{item[column.accessor]}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

DesktopTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
};

export default DesktopTable;
