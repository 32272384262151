import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import $RefParser from 'json-schema-ref-parser';
import { schemaToMock } from '../../../utils/openapi';
import Table from '../../shared/Table';
import Heading from '../../shared/Heading';
import Container from '../../shared/Container';
import Tabs, { Tab } from '../../shared/Tabs';
import Code from '../../shared/Code';
import Button from '../../shared/Button';
import Loader from '../../shared/Loader';
import { AuthConsumer } from '../../App/Auth.context';
import { OverlaysConsumer } from '../../App/Overlays.context';
import styles from './Operation.scss';
import RequestForm from './RequestForm';
import CodeSnippets from './CodeSnippets';

class Operation extends React.Component {
  state = {
    isFetching: true,
    operation: null,
    parameters: [],
    isBody: false,
    body: null,
    response: null,
    server: null
  };

  componentDidMount() {
    const accessToken = localStorage.getItem('access_token');
    const field_paragraphs = this.props;
    const endpoints = []

    // Run over the keys and add element in to endpoints arrray if this is endpoiont.
    Object.keys(field_paragraphs).forEach(function(key) {
      const item = field_paragraphs[key];

      if (item !== undefined && item['apiId']&& item['specUrl']) {
        endpoints.push(item)
      }
    });

    Promise.all(
      endpoints.map(item =>
          window.fetch(process.env.REACT_APP_API_DETAIL + item.apiId + '?format=json', {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }).then(r => r.json())
      )
    )
      .then(schemes => {
        return Promise.all(
          schemes.map(
            item =>
              new Promise((resolve, reject) => {
                $RefParser
                  .dereference(item)
                  .then(schema => resolve(schema))
                  .catch(error => reject(error));
              })
          )
        );
      })
      .then(responses => {
        const operation = this.findOperation(responses);
        const responseWithSchemaIndex = Object.keys(operation.responses).find(
          item => !!operation.responses[item].content
        );
        const responseSchemaMock = responseWithSchemaIndex
          ? schemaToMock(
            operation.responses[responseWithSchemaIndex].content[
              'application/json'
              ].schema
          )
          : null;

        const body = operation.requestBody
          ? schemaToMock(
            operation.requestBody.content['application/json'].schema
          )
          : null;

        const server = (() => {
          if (
            operation.servers &&
            operation.servers[0] &&
            operation.servers[0].url
          ) {
            return operation.servers[0].url;
          }

          return process.env.REACT_APP_API_SANDBOX;
        })();

        this.setState(
          {
            operation,
            isBody: !!operation.requestBody,
            body: JSON.stringify(body, null, 2),
            response: responseSchemaMock,
            isFetching: false,
            server
          },
          () => {
            if (!this.state.operation) {
              this.props.history.replace('/');
            }
            this.updateBreadcrumbs();
            this.setInitialParameters();
          }
        );
      })
      .catch(error => error);
  }

  setInitialParameters = () => {
    const { operation } = this.state;

    if (!operation.parameters) {
      return;
    }

    this.updateParameters(
      operation.parameters
        .filter(item => !!item.schema.type)
        .map(item => ({
          ...item,
          value: this.getParameterInitialValue(item)
        }))
    );
  };

  getParameterInitialValue = param => {
    return param.schema && param.schema.enum && param.schema.enum[0]
      ? param.schema.enum[0]
      : param.example || '';
  };

  makeRequest = () => {
    const { operation, isBody, body } = this.state;

    const server = process.env.REACT_APP_API;

    if (!operation) {
      return;
    }

    const path = this.getFormattedOperationPath();
    const headers = this.getOperationHeadersData();
    const accessToken = localStorage.getItem('access_token');

    headers.Authorization = `Bearer ${accessToken}`

    const query = this.getOperationQueryString();
    const url = `${server}${path}${query}`;

    const options = {
      headers,
      method: operation.method,
      ...(!!isBody && { body })
    };

    window
      .fetch(url, options)
      .then(response => {
        this.selectResponseTabByStatusCode(response.status);
        return response.json();
      })
      .then(response =>
        this.setState({
          response
        })
      )
      .catch(() =>
        this.setState({
          response: null
        })
      );
  };

  getOperationQueryString = () => {
    const params = this.getOperationQueryData();

    return Object.keys(params).length
      ? `?${queryString.stringify(params)}`
      : '';
  };

  getOperationQueryData = () => {
    return this.state.parameters
      .filter(item => item.in === 'query')
      .reduce((result, item) => {
        if (item.value) {
          result[item.name] = item.value;
        } else if (item.schema && item.schema.example) {
          result[item.name] = item.schema.example;
        }
        return result;
      }, {});
  };

  getOperationHeadersData = () => {
    const headers = {};

    headers['content-type'] = 'application/json';
    headers.Authorization = 'Bearer REPLACE_IT_BY_ACCESS_TOKEN(customer2,ttp1)';

    this.state.parameters
      .filter(item => item.in === 'header')
      .reduce((result, item) => {
        if (item.value) {
          headers[item.name] = item.value;
        } else if (item.schema && item.schema && item.schema.example) {
          headers[item.name] = item.schema.example;
        }

        return result;
      }, {});

    return headers;
  };

  getFormattedOperationPath = () => {
    const { operation, parameters } = this.state;
    let path = operation.path;

    parameters
      .filter(item => item.in === 'path')
      .forEach(item => {
        path = path.replace(`{${item.name}}`, item.value);
      });

    return path;
  };

  getOperationId = () => {
    const {
      location: { pathname }
    } = this.props.history;

    return pathname.replace(/^\/|\/$/g, '').split('/')[1];
  };

  findOperation = files => {
    let operation = null;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      operation = this._findOperationInFile(file);

      if (operation) {
        break;
      }
    }

    return operation;
  };

  _findOperationInFile = file => {
    let operation = null;
    const paths = Object.keys(file.paths);

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];

      operation = this._findOperationInFilePath(file, path);

      if (operation) {
        break;
      }
    }

    return operation;
  };

  _findOperationInFilePath = (file, path) => {
    let operation = null;
    const operationId = this.getOperationId();
    const methods = Object.keys(file.paths[path]);

    for (let i = 0; i < methods.length; i++) {
      const method = file.paths[path][methods[i]];

      if (method.operationId === operationId) {
        operation = {
          ...method,
          path,
          method: methods[i]
        };
        break;
      }
    }

    return operation;
  };

  getFormattedSubtitle = operation => {
    return (
      <>
        {operation.summary} <em>{operation.path}</em>
      </>
    );
  };

  updateBreadcrumbs = () => {
    const { operation } = this.state;

    if (operation) {
      this.props.appendBreadcrumbs([
        {
          title: this.getFormattedTitle(),
          url: `/api-explorer/${operation.operationId}`
        }
      ]);
    }
  };

  getTabTypeByKey = key => {
    switch (Number(String(key).charAt(0))) {
      case 2:
        return 'success';
      case 4:
      case 5:
        return 'error';
      default:
        return 'default';
    }
  };

  capitalizeFirstLetters = string => {
    return string
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };

  getFormattedTitle = () => {
    const { operation } = this.state;

    return this.capitalizeFirstLetters(
      `${operation.method} ${operation.tags[0]}`
    );
  };

  updateParameters = (parameters, callback) => {
    this.setState(
      {
        parameters
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  selectResponseTabByStatusCode = status => {
    const index = Object.keys(this.state.operation.responses).findIndex(
      key => key.toString() === status.toString()
    );

    if (index != -1 && this.tabsActions) {
      this.tabsActions.changeTab(index);
    }
  };

  updateBody = body => {
    this.setState({
      body
    });
  };

  render() {
    const {
      isFetching,
      operation,
      parameters,
      response,
      isBody,
      body,
      server
    } = this.state;

    if (isFetching) {
      return <Loader pageLoader />;
    }

    if (!isFetching && !operation) {
      return <div>Not Found</div>;
    }

    const path = this.getFormattedOperationPath();
    const queryData = this.getOperationQueryData();
    const headers = this.getOperationHeadersData();
    const url = encodeURI(`${server}${path}`);

    const codeSnippetsQueryString = Object.keys(queryData).map(key => ({
      name: key,
      value: (queryData[key] || '') + ''
    }));

    const codeSnippetsHeaders = Object.keys(headers).map(key => ({
      name: key,
      value: headers[key] || ''
    }));

    const codeSnippetsPostData = {
      mimeType: 'application/json',
      text: body
    };

    return (
      <Container>
        <div className={styles.wrapper}>
          <Heading
            title={this.getFormattedTitle()}
            subtitle={this.getFormattedSubtitle(operation)}
          />
          <div className={styles.description}>{operation.description}</div>
          {(!!parameters.length || isBody) && (
            <div className={styles.request}>
              <RequestForm
                parameters={parameters}
                onParametersChange={this.updateParameters}
                isBody={isBody}
                body={body || ''}
                onBodyChange={this.updateBody}
              />
            </div>
          )}
          <div className={styles.tryNow}>
            <OverlaysConsumer>
              {({ toggleOverlay }) => (
                <>
                  <AuthConsumer>
                    {({ isAuthorized }) => (
                      <>
                        {isAuthorized ? (
                          <Button
                            className={styles.loginButton}
                            onClick={this.makeRequest}
                          >
                            Try it out
                          </Button>
                        ) : (
                          <Button
                            color="secondary"
                            className={styles.loginButton}
                            onClick={() => {
                              toggleOverlay('login');
                            }}
                          >
                            Sign Up to Try Out
                          </Button>
                        )}
                      </>
                    )}
                  </AuthConsumer>
                </>
              )}
            </OverlaysConsumer>
          </div>
          <div className={styles.response}>
            <h3>Response</h3>
            <Tabs
              listType="balloons"
              actions={actions => (this.tabsActions = actions)}
            >
              {Object.keys(operation.responses).map((key, index) => (
                <Tab key={index} label={key} type={this.getTabTypeByKey(key)}>
                  {operation.responses[key].description}
                </Tab>
              ))}
            </Tabs>
          </div>
          {operation.parameters && (
            <div className={styles.parameters}>
              <Table
                type="compact"
                columns={[
                  { name: 'Name', accessor: 'name', leading: true },
                  { name: 'Description', accessor: 'description' },
                  { name: 'Form', accessor: 'type' }
                ]}
                data={operation.parameters.map(param => ({
                  name: param.name || '-',
                  description: param.description || '-',
                  type: param.type || '-'
                }))}
              />
            </div>
          )}
          {response && (
            <div className={styles.exampleResponse}>
              <h4>Example response</h4>
              <div className={styles.code}>
                <Code language="json">{JSON.stringify(response, null, 2)}</Code>
              </div>
            </div>
          )}
          <div className={styles.codeSnippets}>
            <h3>Example</h3>
            <CodeSnippets
              method={operation.method}
              url={url}
              queryString={codeSnippetsQueryString}
              headers={codeSnippetsHeaders}
              postData={isBody ? codeSnippetsPostData : null}
            />
          </div>
        </div>
      </Container>
    );
  }
}

Operation.propTypes = {
  match: PropTypes.object,
  appendBreadcrumbs: PropTypes.func.isRequired,
  history: PropTypes.object
};

export default withRouter(Operation);
