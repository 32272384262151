import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../Button';
import styles from './TextPageButton.scss';

const TextPageButton = ({ uri, title }) =>
  uri.startsWith('/') ? (
    <Link to={uri} className={styles.link}>
      <Button>{title}</Button>
    </Link>
  ) : (
    <a href={uri} className={styles.link}>
      <Button>{title}</Button>
    </a>
  );

TextPageButton.propTypes = {
  uri: PropTypes.string,
  title: PropTypes.string
};

export default TextPageButton;
