import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../../../assets/icons/Luminor-logo-footer.svg';

import styles from './Footer.scss';

const Footer = ({ elements }) => (
  <div className={styles.footer}>
    <div className={styles.countries}>
      {elements.map((item, i) => (
        <div className={styles.country} key={i}>
          <div className={styles.title}>{item.field_title}</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: item.field_content }}
          />
        </div>
      ))}
    </div>
    <div className={styles.icon}>
      <img src={logo} alt="" />
    </div>
  </div>
);

Footer.propTypes = {
  elements: PropTypes.array
};

export default Footer;
