import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import {
  MOBILE_BREAKPOINT,
  DESKTOP_BREAKPOINT
} from '../../../constants/breakpoints';
import styles from './ListItem.scss';

class ListItem extends React.Component {
  renderLabel = () => {
    const { label } = this.props;

    return (
      label && (
        <div className={styles.labelContainer}>
          <div className={classnames(styles.label, styles[`label-${label}`])}>
            {label}
          </div>
        </div>
      )
    );
  };

  render() {
    const { title, description, onClick } = this.props;

    return (
      <div
        className={classnames(styles.wrapper, {
          [styles.clickable]: !!onClick
        })}
        onClick={onClick}
      >
        <MediaQuery maxDeviceWidth={MOBILE_BREAKPOINT}>
          <div className={styles.content}>
            <div className={styles.title}>{title}</div>
            {this.renderLabel()}
            {description && (
              <div className={styles.description}>{description}</div>
            )}
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={DESKTOP_BREAKPOINT}>
          <>
            <div className={styles.content}>
              <div className={styles.title}>{title}</div>
              {description && (
                <div className={styles.description}>{description}</div>
              )}
            </div>
            {this.renderLabel()}
          </>
        </MediaQuery>
      </div>
    );
  }
}

ListItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default ListItem;
