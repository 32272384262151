import React from 'react';
import { ToastContainer as Toast, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import successIcon from '../../../assets/icons/success-icon.svg';
import errorIcon from '../../../assets/icons/errorIcon.svg';
import styles from './ToastContainer.scss';

class ToastContainer extends React.Component {
  static show = (message, status = 'error') => {
    toast[status](
      <div className={styles.toastMessage}>
        <div className={styles.icon}>
          {status === 'success' && <img src={successIcon} alt="" />}
          {status === 'error' && <img src={errorIcon} alt="" />}
        </div>
        <div className={styles.message}>{message}</div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.toastContainer}>
        <Toast
          autoClose={3000}
          hideProgressBar={true}
          position="top-center"
          draggable={false}
        />
      </div>
    );
  }
}

export default ToastContainer;
