import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ChevronCircle.scss';

const ChevronCircle = ({ className, direction }) => (
  <i
    className={classnames(
      styles.wrapper,
      className,
      styles[`direction-${direction}`]
    )}
  />
);

ChevronCircle.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left'])
};

ChevronCircle.defaultprops = {
  direction: 'top'
};

export default ChevronCircle;
