import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Container';
import TextToReact from '../TextToReact';
import styles from './CookieBar.scss';

const CookieBar = ({ message, onAgree }) => (
  <div className={styles.cookieBarContainer}>
    <Container wide={true}>
      <div className={styles.cookieBar}>
        <div className={styles.textHolder}>
          <TextToReact>{message}</TextToReact>
        </div>
        <div className={styles.buttonHolder} onClick={onAgree}>
          <div className={styles.button}>AGREE</div>
        </div>
      </div>
    </Container>
  </div>
);

CookieBar.propTypes = {
  message: PropTypes.string.isRequired,
  onAgree: PropTypes.func.isRequired
};

export default CookieBar;
