import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import {
  MOBILE_BREAKPOINT,
  DESKTOP_BREAKPOINT
} from '../../../constants/breakpoints';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';

class Table extends React.Component {
  render() {
    return (
      <>
        <MediaQuery minDeviceWidth={DESKTOP_BREAKPOINT}>
          <DesktopTable {...this.props} />
        </MediaQuery>
        <MediaQuery maxDeviceWidth={MOBILE_BREAKPOINT}>
          <MobileTable {...this.props} />
        </MediaQuery>
      </>
    );
  }
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['default', 'compact'])
};

Table.defaultProps = {
  type: 'default'
};

export default Table;
