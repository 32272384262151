import Amplify, { Auth } from 'aws-amplify';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import CognitoConfig from '../../../actions/awsCognotoConf';
import Modal from '../../shared/Modal';
import Form from '../../shared/Form';
import authBackground from '../../../assets/icons/auth-background.svg';
import ToastContainer from '../../shared/ToastContainer';
import SocialLogin from './SocialLogin';
import styles from './AuthModal.scss';

Amplify.configure(CognitoConfig);

class AuthModal extends React.Component {
  initialState = {
    isLoading: false,
    credentials: {
      name: '',
      pass: ''
    }
  };

  state = this.initialState;

  resetState = () => this.setState(this.initialState);

  isLogin = () => this.props.authType === 'login';

  onCredentialsChange = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      credentials: {
        ...prevState.credentials,
        [name]: value
      }
    }));
  };

  onSubmit = () => {
    const { credentials } = this.state;

    this.setState({
      isLoading: true
    });

    if (this.isLogin()) {
      Auth.signIn({
        username: credentials.name,
        password: credentials.pass
      })
        .then(user => {
          this.userLoginRoutine(user.username)
        })
        .catch(message => {
          const msg = (message.message) ? message.message : message;

          ToastContainer.show(msg)
          this.setState({
            isLoading: false
          });
        })
    } else {
      // Register.
      Auth.signUp({
        username: credentials.name,
        password: credentials.pass
      })
      .then(data => {
        if (data.user) {
          ToastContainer.show('Please verify your mail address.');
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false
        });
        this.props.history.push('/');
      })
      .catch((message) => {
        ToastContainer.show(message.message)
        this.setState({
          isLoading: false
        });
      })
    }
  };

  userLoginRoutine (tppId) {
    const authToken = 'Bearer ' + tppId

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': authToken,
      Origin: document.URL
    }

    fetch(process.env.REACT_APP_API + '/tpp ', {
      method: 'POST',
      headers: headers
    })
    .then(() => {
      const { credentials } = this.state;

      this.setState({
        isLoading: false
      });
      this.onSuccess({
        message: '',
        access_token: tppId,
        name: credentials.name
      })
    })
  }

  onSuccess = (response, isSocialAuth) => {
    const { message, access_token, name } = response;

    if (this.isLogin() || isSocialAuth) {
      this.props.onLogin({ access_token, name });
    } else {
      ToastContainer.show(message);
      this.props.history.push('/');
    }
  };

  getBottomContent = () => {
    if (this.isLogin()) {
      return {
        left: (
          <Link
            to="/"
            onClick={() => this.props.toggleOverlay('forgotPassword')}
          >
            Forgot password?
          </Link>
        ),
        right: (
          <div>
            {'Don\'t have an account? '}
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                this.props.toggleOverlay('register');
              }}
            >
              Sign up
            </a>
          </div>
        )
      };
    } else {
      return {
        right: (
          <div>
            By continuing you agree with{' '}
            <a
              href={this.props.terms}
              target="_blank"
              rel="noopener noreferrer"
            >
              Developers Portal Terms
            </a>
          </div>
        )
      };
    }
  };

  render() {
    const {
      credentials: { name, pass },
      isLoading
    } = this.state;

    return (
      <Modal
        onAfterOpen={this.resetState}
        title={(() => {
          if (this.isLogin()) {
            return 'Log in to Luminor for Developers';
          } else {
            return 'Sign up to Luminor for Developers';
          }
        })()}
        bottomContent={this.getBottomContent()}
        isOpen={this.props.isOpen}
        style={{
          content: {
            backgroundImage: `url(${authBackground})`,
            backgroundSize: '90% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left 20%'
          }
        }}
      >
        <div className={styles.wrapper}>
          {this.props.socialAppsData && (
            <SocialLogin
              onSuccess={this.onSuccess}
              socialAppsData={this.props.socialAppsData}
            />
          )}

          <div className={styles.separator}>or</div>

          <Form onSubmit={this.onSubmit}>
            <Form.TextField
              label="EMAIL"
              type="email"
              name="name"
              onChange={this.onCredentialsChange}
              value={name}
            />

            <Form.TextField
              name="pass"
              label="PASSWORD"
              onChange={this.onCredentialsChange}
              value={pass}
              type="password"
            />

            <Form.Button disabled={!name || !pass || isLoading}>
              {this.isLogin() ? 'Log In' : 'Register Now'}
            </Form.Button>
          </Form>
        </div>
      </Modal>
    );
  }
}

AuthModal.propTypes = {
  isOpen: PropTypes.bool,
  authType: PropTypes.oneOf(['login', 'register']),
  toggleOverlay: PropTypes.func.isRequired,
  changeAuthType: PropTypes.func,
  terms: PropTypes.string,
  history: PropTypes.object.isRequired,
  onLogin: PropTypes.func.isRequired,
  socialAppsData: PropTypes.object.isRequired
};

export default withRouter(AuthModal);
