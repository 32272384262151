import React from 'react';
import Amplify from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import jwtDecode from 'jwt-decode'
import CognitoConfig from '../../../../actions/awsCognotoConf';
import { _fetch } from '../../../../actions/api.config';
import ToastContainer from '../../../shared/ToastContainer';
import styles from './SocialLogin.scss';

Amplify.configure(CognitoConfig);

class SocialLogin extends React.Component {
  componentDidMount() {
    const regex = /access_token=([^&]+)/gm;
    const token = regex.exec(this.props.location.hash);

    if (token) {
      fetch(CognitoConfig.Auth.poolDomain + '/oauth2/userInfo?scope=openid', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token[1]
        }})
        .then(function(response) {
          return response.json();
        })
        .then(function(userdata) {
          const decodedJwt = jwtDecode(token[1]);
          
          localStorage.setItem('access_token', decodedJwt.username);
          localStorage.setItem('user_name', userdata.email);
        })
        .then(() => {
          const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('access_token'),
            Origin: document.URL
          }

          fetch(process.env.REACT_APP_API + '/tpp ', {
            method: 'POST',
            headers: headers
          }).then(() => {
            this.props.history.push('/');
          })
        })
        .catch((message) => {
          ToastContainer.show(message.message)
        })
    }
  }

  sendAuthRequest = (authToken, socialNetwork) => {
    _fetch('user/social_login', {
      method: 'POST',
      body: {
        authToken,
        socialNetwork
      }
    }).then(response => this.props.onSuccess(response, true));
  };

  renderLoginOption = (type, name) => (
    <div className={classnames(styles.option, styles[type])} onClick={() => this.loginWithSocialMedia(name)}>
      <div className={styles.logo}>
        <div className={styles.icon} />
      </div>
      <div className={styles.name}>{name}</div>
    </div>
  );

  isIE = () => {
    return document.documentMode || /Edge/.test(navigator.userAgent);
  };

  loginWithSocialMedia = (provider) => {
    const domain = CognitoConfig.Auth.poolDomain + '/oauth2/authorize?';
    const parameters = {
      client_id: CognitoConfig.Auth.userPoolWebClientId,
      redirect_uri: window.location.origin + '/?m=login',
      response_type: 'token',
      identity_provider: provider,
      scope: 'openid'
    }
    const searchParams = new URLSearchParams(parameters);

    window.location.replace(domain + searchParams);
  }

  render() {
    const { location, socialAppsData } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.options}>
          <GoogleLogin
            clientId={socialAppsData.google}
            render={() =>
              this.renderLoginOption('google', 'Google')
            }
            onSuccess={data => {
              this.sendAuthRequest(data.tokenId, 'google');
            }}
            onFailure={() => {}}
            uxMode={this.isIE() ? 'redirect' : 'popup'}
            redirectUri={`${window.location.origin}?m=login&isSignedIn`}
            isSignedIn={location.search.includes('isSignedIn')}
          />
          <FacebookLogin
            appId={socialAppsData.facebook}
            fields="name,email,picture"
            redirectUri={`${window.location.origin}?m=login`}
            callback={data => {
              this.sendAuthRequest(data.accessToken, 'facebook');
            }}
            render={() =>
              this.renderLoginOption('facebook', 'Facebook')
            }
          />
        </div>
      </div>
    );
  }
}

SocialLogin.propTypes = {
  location: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  socialAppsData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(SocialLogin);
