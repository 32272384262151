import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import Container from '../Container';
import closeSvg from '../../../assets/icons/close.svg';
import styles from './Modal.scss';

class Modal extends React.Component {
  componentDidMount() {
    ReactModal.setAppElement('#app');
  }

  render() {
    const {
      title,
      isOpen,
      bottomContent,
      style,
      className,
      onAfterOpen
    } = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        className={cx(styles.modal, className)}
        overlayClassName={styles.overlay}
        closeTimeoutMS={200}
        style={style}
        parentSelector={() => document.querySelector('#app')}
        onAfterOpen={onAfterOpen}
      >
        <div className={styles.scroller}>
          <div className={styles.modalLayoutHolder}>
            <div className={styles.modalLayoyt}>
              <div className={styles.heading}>
                <Container wide={true}>
                  <div className={styles.headingHolder}>
                    <h2>{title}</h2>
                    <div
                      className={styles.closeButton}
                      onClick={() => this.props.history.push({ search: '' })}
                    >
                      <img src={closeSvg} alt="" />
                    </div>
                  </div>
                </Container>
              </div>
              <div className={styles.contentHolder}>
                <div className={styles.centered}>
                  <div className={styles.centeredInner}>
                    <div className={styles.content}>
                      <Container>{this.props.children}</Container>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.bottomContent}>
                <Container wide={true}>
                  <div className={styles.contentHolder}>
                    <div className={styles.side}>
                      {bottomContent && bottomContent.left && (
                        <>{bottomContent.left}</>
                      )}
                    </div>
                    <div className={styles.side}>
                      {bottomContent && bottomContent.right && (
                        <>{bottomContent.right}</>
                      )}
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  history: PropTypes.object.isRequired,
  bottomContent: PropTypes.shape({
    left: PropTypes.node,
    right: PropTypes.node
  }),
  style: PropTypes.object,
  className: PropTypes.string,
  onAfterOpen: PropTypes.func
};

export default withRouter(Modal);
