import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MediaQuery from 'react-responsive';
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT
} from '../../../../constants/breakpoints';
import { OverlaysConsumer } from '../../../App/Overlays.context';
import { AuthConsumer } from '../../../App/Auth.context';
import StickyAuthButtons from '../../../shared/Header/StickyAuthButtons';
import arrowIcon from '../../../../assets/icons/arrow-down.svg';

import styles from './Hero.scss';

const Hero = ({ title, subTitle, image, onScrollDown }) => (
  <div className={styles.hero}>
    <div className={styles.contentHolder}>
      <div className={cx(styles.title, styles.top)}>{title}</div>
      <img src={image.url} alt={image.alt} className={styles.luminorLogo} />
      <div className={cx(styles.title, styles.bottom)}>{subTitle}</div>

      <MediaQuery minWidth={DESKTOP_BREAKPOINT}>
        <div className={styles.buttonDown} onClick={onScrollDown}>
          <img src={arrowIcon} alt="" />
        </div>
      </MediaQuery>

      <AuthConsumer>
        {({ isAuthorized }) => (
          <>
            {!isAuthorized && (
              <MediaQuery maxWidth={MOBILE_BREAKPOINT}>
                <div className={styles.mobileButton}>
                  <OverlaysConsumer>
                    {({ toggleOverlay }) => (
                      <StickyAuthButtons toggleOverlay={toggleOverlay} />
                    )}
                  </OverlaysConsumer>
                </div>
              </MediaQuery>
            )}
          </>
        )}
      </AuthConsumer>
    </div>
  </div>
);

Hero.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string
  }).isRequired,
  onScrollDown: PropTypes.func.isRequired
};

export default Hero;
