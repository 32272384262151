import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { _fetch } from '../../../actions/api.config';
import Breadcrumbs from '../../shared/Breadcrumbs';
import Loader from '../../shared/Loader';
import TextPage from '../TextPage';
import HomePage from '../HomePage';
import OperationsList from '../OperationsList';
import Operation from '../Operation';
import initialData from '../../../assets/json/initial_data'

class PageResolver extends React.Component {
  state = {
    isFetching: true,
    page: {}
  };

  componentDidMount() {
    // Check if this is api-explorer/{operation} page. If it is, replace path name with a correct one, because url will
    // be broken in that case.
    let url = 'content' + this.props.pathname;
    const regExp = new RegExp(/\/(...)*\/(...)*/gm);

    if (regExp.test(this.props.pathname)) {
      url = '/openapi/apis' + '?format=json'
    }

    _fetch(url)
      .then(page => {
        const regExp = new RegExp(/\/(...)*\/(...)*/gm);
        
        // Add breadcrumbs if needed.
        if (!page.breadcrumbs && this.props.pathname !== '/api-explorer' && !regExp.test(this.props.pathname)) {
          let pageTitle = '';

          for(const i in initialData.user_menu) {
            const item = initialData.user_menu[i];
            const regExp = new RegExp('(...)*\\' + item.relative)

            if (regExp.test(this.props.pathname)) {
              pageTitle = item.title;
            }
          }

          page['breadcrumbs'] = [
            {title: 'Home', url: '/'},
            {title: pageTitle, url: url}
          ];
        } else {
          page['breadcrumbs'] = [
            {title: 'Home', url: '/'},
            {title: 'API Explorer', url: '/api-explorer'}
          ];
        }
        this.setState({
          isFetching: false,
          page
        });
      })
      .catch(() => {
        this.props.history.replace('/');
      });
  }

  getBreadcrumbs = () => (
    <Breadcrumbs
      list={this.state.page.breadcrumbs}
      currentUrl={this.props.pathname}
    />
  );

  appendBreadcrumbs = breadcrumbs => {
    const { page } = this.state;

    this.setState({
      page: {
        ...page,
        breadcrumbs: page.breadcrumbs.concat(breadcrumbs)
      }
    });
  };

  /**
   * Check if given data is api list.
   *
   * @param data
   * @returns {boolean}
   */
  ifApiList = (data) => {
    // If this is an array and its keys is something that we need, then it can be api list, in this case return true.
    if (Array.isArray(data)) {
      // Get keys of object inside.
      const keys = Object.keys(data[0])
      const regExp = new RegExp(/\/(...)*\/(...)*/gm);

      // This will mean that user is visiting operation page.
      if (regExp.test(this.props.pathname)) {
        return false
      }

      if (keys[0] === 'apiId' && keys[1] === 'specUrl') {
        return true
      }
    }
    // This is not api listing.
    return false
  }

  render() {
    const { isFetching, page } = this.state;
    const { customers } = this.props;
    const ifApiList = this.ifApiList(page)

    if (isFetching) {
      return <Loader pageLoader />;
    }

    switch (page.type) {
      case 'page':
        return (
          <>
            {this.getBreadcrumbs()}
            <TextPage customers={customers} {...page} />
          </>
        );
      case 'landing_page':
        return <HomePage {...page} />;
      case 'api_page':
        return (
          <>
            {this.getBreadcrumbs()}
            <OperationsList {...page} />
          </>
        );
      case 'api_page_inner':
        return (
          <>
            {this.getBreadcrumbs()}
            <Operation appendBreadcrumbs={this.appendBreadcrumbs} {...page} />
          </>
        );
      default:
        if (ifApiList) {
          return (
              <>
                {this.getBreadcrumbs()}
                <OperationsList {...page} />
              </>
          );
        } else if (page[0] && page[0].apiId) {
          return (
              <>
                {this.getBreadcrumbs()}
                <Operation appendBreadcrumbs={this.appendBreadcrumbs} {...page} />
              </>
          );
        }
        else {
          return (
              <>
                {this.getBreadcrumbs()}
                <TextPage customers={customers} {...page} />
              </>
          );
        }
    }
  }
}

PageResolver.propTypes = {
  location: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  customers: PropTypes.array
};

export default withRouter(PageResolver);
