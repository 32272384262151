import React from 'react';
import PropTypes from 'prop-types';
import styles from './Heading.scss';

const Heading = ({ title, titleTag = 'h2', subtitle }) => {
  return (
    <div className={styles.heading}>
      {React.createElement(titleTag, { className: styles.title }, title)}
      {Boolean(subtitle) && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  );
};

Heading.propTypes = {
  titleTag: PropTypes.string,
  title: PropTypes.node,
  subtitle: PropTypes.node
};

export default Heading;
