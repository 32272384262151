import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Heading from '../../shared/Heading';
import Text from '../../shared/Text';
import Collapsible from '../../shared/Collapsible';
import TextPageButton from '../../shared/TextPageButton';
import TokensWidget from '../../shared/TokensWidget';
import ApplicationIdentityWidget from '../../shared/ApplicationIdentityWidget';
import Container from '../../shared/Container';
import initialData from '../../../assets/json/initial_data'
import styles from '../Operation/Operation.scss';

class TextPage extends React.Component {

  render() {
    let {
      title,
      body,
      field_sub_title,
      field_paragraphs: paragraphs
    } = this.props;

    // For Application Widget.
    if (this.props.clientId) {
      paragraphs = [{type: 'application_identity'}]
      title = 'Application identity'
    }

    // For tokens page.
    if (this.ifTokensPage()) {
      paragraphs = [{type: 'tokens'}];
      title = 'Tokens';
      field_sub_title = initialData.user_menu[0].data.field_sub_title + ' <span>' + initialData.user_menu[0].data.headingUrl + '</span>';
      body = initialData.user_menu[0].data.infoText;
    }

      return (
          <Container>
            <Heading
                title={title}
                subtitle={
                  <span className={styles.heading} dangerouslySetInnerHTML={{__html: field_sub_title}}/>
                }
            />
            <Text className={styles.description}>{body}</Text>
            {Boolean(paragraphs && paragraphs.length) &&
            paragraphs.map((paragraph, i) => {
              switch (paragraph.type) {
                case 'collapsible':
                  return <Collapsible key={i} list={paragraph.field_elements}/>;
                case 'title_with_content':
                  return (
                      <Fragment key={i}>
                        <h3>{paragraph.field_title}</h3>
                        <Text>{paragraph.field_content}</Text>
                      </Fragment>
                  );
                case 'button':
                  return (
                      <TextPageButton
                          key={i}
                          uri={paragraph.field_button.uri}
                          title={paragraph.field_button.title}
                      />
                  );
                case 'tokens':
                  return (
                      <TokensWidget
                          key={i}
                          codeSnippetUri='https://test.api.xx.xxxxxxx.xxxxxxx.com/v1/accounts'
                          users={this.cleanUserData()}
                      />
                  );
                case 'application_identity':
                case 'tpp':
                  return <ApplicationIdentityWidget key={i}/>;
                default:
                  return null;
              }
            })}
          </Container>
      );
  }

  // Clone object and remove all data that is not needed.
  cleanUserData () {
    const userData = JSON.parse(JSON.stringify(this.props));

    delete userData.breadcrumbs;

    const cleanUserData = [];

    for (const i in userData) {
      cleanUserData.push(userData[i]);
    }

    return cleanUserData;
  }

  // Check if this is a tokens page.
  ifTokensPage () {
    if (this.props[0] && this.props[0].access_token && this.props[0].expires_in && this.props[0].userName) {
      return true;
    }
    return false;
  }
}

TextPage.propTypes = {
  clientId: PropTypes.string,
  title: PropTypes.string,
  field_sub_title: PropTypes.string,
  body: PropTypes.string,
  field_paragraphs: PropTypes.array,
};

export default TextPage;
