import React from 'react';
import PropTypes from 'prop-types';
import Container from '../../Container';
import Button from '../../Button';
import styles from './StickyAuthButtons.scss';

const StickyAuthButtons = ({ toggleOverlay }) => (
  <div className={styles.stickyAuthButtons}>
    <Container wide={true}>
      <div className={styles.buttonsHolder}>
        <div>
          <Button
            color="secondary"
            fluid
            onClick={() => toggleOverlay('login')}
          >
            log in
          </Button>
        </div>
        <div>
          <Button fluid onClick={() => toggleOverlay('register')}>
            SIGN UP
          </Button>
        </div>
      </div>
    </Container>
  </div>
);

StickyAuthButtons.propTypes = {
  toggleOverlay: PropTypes.func
};

export default StickyAuthButtons;
