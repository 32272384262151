import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { _fetch } from '../../../actions/api.config';
import Container from '../../shared/Container';
import logo from '../../../assets/icons/Luminor_logo.svg';
import successImage from '../../../assets/icons/verify-success.svg';
import errorImage from '../../../assets/icons/verify-errror.svg';
import Modal from '../../shared/Modal';
import styles from './VerifyModal.scss';

class VerifyModal extends React.Component {
  state = {
    isLoading: true,
    isSuccess: false,
    email: null
  };

  componentDidMount() {
    const {
      history: {
        location: { search }
      }
    } = this.props;
    const params = search.split('code=', 2);
    const code = params[1];

    if (!code) {
      return this.props.history.push('/');
    }

    _fetch('user/verify' + code)
      .then(({ access_token, name, mail }) => {
        this.props.onLogin({ access_token, name }, true);
        this.setState({
          isSuccess: true,
          email: mail
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  }

  render() {
    const { isLoading, isSuccess } = this.state;

    return (
      <Modal isOpen={true} className={styles.verifyModal}>
        <Container>
          {isLoading ? (
            <div />
          ) : (
            <>
              {isSuccess ? (
                <div>
                  <div className={styles.logo}>
                    <img src={logo} alt="" />
                  </div>
                  <div className={styles.imageHolder}>
                    <img src={successImage} alt="" />
                  </div>
                  <div className={styles.title}>E-mail verified</div>
                  <div className={styles.text}>
                    {`You have successfully verified ${this.state.email}`}
                  </div>
                </div>
              ) : (
                <div>
                  <div className={styles.logo}>
                    <img src={logo} alt="" />
                  </div>
                  <div className={styles.imageHolder}>
                    <img src={errorImage} alt="" />
                  </div>
                  <div className={styles.title}>Something went wrong</div>
                  <div className={styles.text}>
                    This email verification link is not valid any more
                  </div>
                </div>
              )}
            </>
          )}
        </Container>
      </Modal>
    );
  }
}

VerifyModal.propTypes = {
  history: PropTypes.object.isRequired,
  onLogin: PropTypes.func.isRequired
};

export default withRouter(VerifyModal);
