const theme = {
  plain: {
    color: '#000'
  },
  styles: [
    {
      types: ['changed'],
      style: {
        color: 'rgb(162, 191, 252)',
        fontStyle: 'italic'
      }
    },
    {
      types: ['deleted'],
      style: {
        color: 'rgba(239, 83, 80, 0.56)',
        fontStyle: 'italic'
      }
    },
    {
      types: ['inserted', 'attr-name'],
      style: {
        color: '#90C2E4',
        fontStyle: 'italic'
      }
    },
    {
      types: ['comment'],
      style: {
        color: 'rgb(99, 119, 119)',
        fontStyle: 'italic'
      }
    },
    {
      types: ['string', 'url'],
      style: {
        color: '#90C2E4'
      }
    },
    {
      types: ['variable'],
      style: {
        color: '#000'
      }
    },
    {
      types: ['number'],
      style: {
        color: 'rgb(247, 140, 108)'
      }
    },
    {
      types: ['builtin', 'char', 'constant', 'function'],
      style: {
        color: '#000'
      }
    },
    {
      // This was manually added after the auto-generation
      // so that punctuations are not italicised
      types: ['punctuation'],
      style: {
        color: '#000'
      }
    },
    {
      types: ['selector', 'doctype'],
      style: {
        color: '#000',
        fontStyle: 'italic'
      }
    },
    {
      types: ['class-name'],
      style: {
        color: '#90C2E4'
      }
    },
    {
      types: ['tag', 'operator', 'keyword'],
      style: {
        color: '#000'
      }
    },
    {
      types: ['boolean'],
      style: {
        color: '#90C2E4'
      }
    },
    {
      types: ['property'],
      style: {
        color: '#000'
      }
    },
    {
      types: ['namespace'],
      style: {
        color: '#000'
      }
    }
  ]
};

export default theme;
