import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const OverlaysContext = React.createContext({});

class OverlaysProvider extends React.Component {
  overlays = [
    {
      name: 'login',
      url: '?m=login',
      stateName: 'isLoginOpen'
    },
    {
      name: 'register',
      url: '?m=register',
      stateName: 'isRegisterOpen'
    },
    {
      name: 'forgotPassword',
      url: '?m=forgotPassword',
      stateName: 'isforgotPasswordOpen'
    },
    {
      name: 'passwordReset',
      url: '?m=passwordReset',
      stateName: 'ispasswordResetOpen'
    },
    {
      name: 'emailVerify',
      url: '?m=verify',
      stateName: 'isEmailVerifyOpen'
    },
    {
      name: 'menu',
      stateName: 'isNavOpen'
    },
    {
      name: 'userMenu',
      stateName: 'isUserNavOpen'
    }
  ];

  state = {
    isNavOpen: false,
    isAuthOpen: false,
    isRegisterOpen: false,
    isLoginOpen: false,
    isforgotPasswordOpen: false
  };

  componentDidMount() {
    this.props.history.listen(location => this.checkOpenOverlays(location));
    this.checkOpenOverlays(this.props.history.location);
  }

  checkOpenOverlays = ({ search }) => {
    this.overlays.forEach(modal => {
      this.setState({
        [modal.stateName]: search.includes(modal.url)
      });
    });
  };

  toggleOverlay = name => {
    const { url, stateName } = this.overlays.find(ov => ov.name === name);
    const oldState = this.state[stateName];

    Promise.all(
      this.overlays.map(
        overlay =>
          new Promise(resolve => {
            const isSelected = overlay.name === name;

            this.setState(
              {
                [isSelected ? stateName : overlay.stateName]: isSelected
                  ? !oldState
                  : false
              },
              () => resolve()
            );
          })
      )
    ).then(() => {
      if (url) {
        this.props.history.push({
          // pathname: '/',
          search: oldState ? '' : url
        });
      }
    });
  };

  isOverlayOpen = name => {
    return this.state[this.overlays.find(o => o.name === name).stateName];
  };

  render() {
    return (
      <OverlaysContext.Provider
        value={{
          toggleOverlay: this.toggleOverlay,
          isOverlayOpen: this.isOverlayOpen
        }}
      >
        {this.props.children}
      </OverlaysContext.Provider>
    );
  }
}

OverlaysProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(OverlaysProvider);
export const OverlaysConsumer = OverlaysContext.Consumer;
