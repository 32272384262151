import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class Logout extends React.Component {
  componentDidMount() {
    this.props.history.push('/');
    if (this.props.logout) {
      this.props.logout();
      if (this.props.onLoggedOut) {
        this.props.onLoggedOut();
      }
    }
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onLoggedOut: PropTypes.func
};

export default withRouter(Logout);
