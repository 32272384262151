import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Loader.scss';

const Loader = ({ pageLoader }) => (
  <div
    className={cx(styles.loaderHolder, {
      [styles.pageLoader]: pageLoader
    })}
  >
    <div className={styles.loader}>
      <div className={styles.minutesHand} />
      <div className={styles.hoursHand} />
    </div>
  </div>
);

Loader.propTypes = {
  pageLoader: PropTypes.bool
};

export default Loader;
