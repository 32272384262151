import queryString from 'query-string';
import { history } from '../store/configureStore';
import initialData from '../assets/json/initial_data'

export const _fetch = (url, options = {}) => {
  // todo: make env variable from this and point it to backend.
  // const API_BASE = 'https://drupal-api.developer.luminoropenbanking.com/api/'
  const accessToken = localStorage.getItem('access_token');

  url = url.replace('content/tpp', '/tpp')
  url = url.replace('content/tokens', '/tpp/psu')

  return new Promise((resolve, reject) => {
    // const authToken = 'Bearer ttp1'
    const fetchData = {
      method: options.method || 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        // Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Origin: document.URL,
      }
    };

    // Todo: review this code after AWS Cognito implementation.
    // (options.public || !accessToken) &&
    //   delete fetchData.headers['Authorization'];

    options.body !== undefined
      ? (fetchData['body'] = JSON.stringify(options.body))
      : fetchData;

    let fetchUrl = `${process.env.REACT_APP_API}${url}`;

    if (options.params) {
      fetchUrl += `?${queryString.stringify(options.params)}`;
    }

    // Will check if given url is presented in a static menu structure. If yes, replace url to point app to local files.
    const searchForUrl = url.replace(/content/,'')
    const jsonMenuIndex = initialData.menu_items.findIndex(x => x.relative === searchForUrl)

    if (jsonMenuIndex !== -1) {
      fetchUrl = initialData.menu_items[jsonMenuIndex].json
    } else if (url === 'initial_data') {
      // App is requesting initial data json with menu structure, google and facebook api keys and etc. Replace
      // request url to point request to a local assets folder.
      fetchUrl = process.env.REACT_APP_PUBLIC_PATH + '/initial_data.json'
    } else if (url === 'content/') {
      // For homepage.
      fetchUrl = process.env.REACT_APP_PUBLIC_PATH + '/content/homepage.json'
    }

    fetch(fetchUrl, fetchData)
      .then(response => {
        {
          if (response.status === 401 || response.status === 403) {
            history.push('/logout');
            reject({status: response.status});
          } else if (response.status < 400) {
            response
              .json()
              .then(res => resolve(res))
              .catch(error => resolve(error));
          } else {
            response.json().then(json => reject(json));
          }
        }
      })
      .catch(error => reject(error));
  });
};
