import React from 'react';
import Amplify, { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from '../../shared/Modal';
import Form from '../../shared/Form';
import ToastContainer from '../../shared/ToastContainer';
import CognitoConfig from '../../../actions/awsCognotoConf';
import authBackground from '../../../assets/icons/auth-background.svg';
import styles from '../ForgotPasswordModal/ForgotPasswordModal.scss';
Amplify.configure(CognitoConfig);

class PasswordResetModal extends React.Component {
  initialState = {
    isLoading: false,
    code: null,
    message: null,
    isFetching: true,
    credentials: {
      password: '',
      code: '',
      mail: ''
    }
  };

  state = this.initialState;

  componentDidMount() {
    this.setState(
      {
        isFetching: false,
        credentials: {
          mail: localStorage.getItem('mail') || ''
        }
      }
    );
  }

  resetState = () => {};

  onCredentialsChange = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      ...prevState,
      credentials: {
        ...prevState.credentials,
        [name]: value
      }
    }));
  };

  confirmNewPassword = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
       Auth.forgotPasswordSubmit(this.state.credentials.mail, this.state.credentials.code, this.state.credentials.password)
          .then(() => {
            ToastContainer.show('Your password has been changed.', 'success');
            this.props.history.push('/');
          })
          .catch(err => ToastContainer.show(err))
          .finally(() => {
               this.setState({
                 isLoading: false
               });
             });
      }
    );
  };

  render() {
    const {
      isLoading
    } = this.state;

    return (
      <Modal
        onAfterOpen={this.resetState}
        isOpen={this.props.isOpen}
        title="Reset your password"
        className={styles.forgotPassword}
        style={{
          content: {
            backgroundImage: `url(${authBackground})`,
            backgroundSize: '90% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left 20%'
          }
        }}
      >
        {!this.state.isFetching && (
          <Form onSubmit={this.confirmNewPassword} title={this.state.message}>
            <p>{this.state.credentials.mail}</p>
            <Form.TextField
              label="Verification code"
              name="code"
              onChange={this.onCredentialsChange}
              type="text"
            />
            <Form.TextField
              label="New password"
              name="password"
              type="password"
              onChange={this.onCredentialsChange}
              value={this.state.credentials.password}
            />

            <Form.Button
              className={styles.submitButton}
              disabled={!this.state.credentials.mail || isLoading}
            >
              Confirm password
            </Form.Button>
          </Form>
        )}
      </Modal>
    );
  }
}

PasswordResetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool,
  history: PropTypes.object,
  onLogin: PropTypes.func.isRequired
};

export default withRouter(PasswordResetModal);
