import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../../../shared/ListItem';
import { history } from '../../../../store/configureStore';
import styles from './TagSection.scss';
import DownloadButton from './DownloadButton';

class TagSection extends React.Component {
  pushToOperationPage = operationId => {
    history.push(`/api-explorer/${operationId}`);
  };

  render() {
    const { tag, fields } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.topLine}>
            <h2>{tag.name}</h2>
            <div className={styles.download}>
              <DownloadButton
                downloadItems={[
                  {
                    title: 'JSON',
                    filename: `${fields.field_title}.json`,
                    url: process.env.REACT_APP_API_DETAIL + fields.apiId + '?format=json'
                  },
                  {
                    title: 'YAML',
                    filename: `${fields.field_title}.yaml`,
                    url: process.env.REACT_APP_API_DETAIL + fields.apiId
                  }
                ]}
              />
            </div>
          </div>
          <div className={styles.description}>{tag.description}</div>
        </div>
        <div className={styles.list}>
          {tag.operations.map((operation, index) => (
            <ListItem
              key={index}
              title={operation.summary}
              description={operation.description}
              label={operation.method}
              onClick={() => {
                this.pushToOperationPage(operation.operationId);
              }}
            />
          ))}
        </div>
      </div>
    );
  }
}

TagSection.propTypes = {
  tag: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired
};

export default TagSection;
