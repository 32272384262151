/* eslint-disable no-undef */
import styles from './styles.css';

ace.define(
  'ace/theme/luminor',
  ['require', 'exports', 'module', 'ace/lib/dom'],
  function(acequire, exports) {
    exports.isDark = false;
    exports.cssClass = 'ace-luminor';
    exports.cssText = styles;

    const dom = acequire('../lib/dom');

    dom.importCssString(exports.cssText, exports.cssClass);
  }
);
