function schemaToMock(schema) {
  return parseByType(schema);
}

function parseByType(schema) {
  switch (schema.type) {
    case 'object':
      return parseObject(schema);
    case 'array':
      return parseArray(schema);
    case 'string':
      return parseString(schema);
    case 'number':
      return parseNumber(schema);
    case 'boolean':
      return parseBoolean(schema);
    default:
      if (schema.properties) {
        return parseObject(schema);
      } else {
        return null;
      }
  }
}

function parseObject(schema) {
  return Object.keys(schema.properties).reduce(function(acc, curr) {
    return { ...acc, [curr]: parseByType(schema.properties[curr]) };
  }, {});
}

function parseArray(schema) {
  if (schema.items) {
    if (schema.items.properties) {
      return [
        parseByType({ type: 'object', properties: schema.items.properties })
      ];
    } else if (schema.items.example) {
      return [schema.items.example];
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function parseString(schema) {
  if (schema.example) {
    return schema.example;
  } else if (schema.enum && schema.enum[0]) {
    return schema.enum[0];
  } else {
    return 'string';
  }
}

function parseNumber(schema) {
  return schema.example || 0;
}

function parseBoolean(schema) {
  return schema.example || true;
}

export { schemaToMock };
