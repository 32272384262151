import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Container.scss';

const Container = ({ children, wide }) => (
  <div
    className={cx(styles.container, {
      [styles.wide]: wide
    })}
  >
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool
};

export default Container;
