import React from 'react';
import Amplify, { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from '../../shared/Modal';
import Form from '../../shared/Form';
import ToastContainer from '../../shared/ToastContainer';
import CognitoConfig from '../../../actions/awsCognotoConf';
import authBackground from '../../../assets/icons/auth-background.svg';
import styles from './ForgotPasswordModal.scss';
Amplify.configure(CognitoConfig);

class ForgotPasswordModal extends React.Component {
  initialState = {
    isLoading: false,
    credentials: {
      mail: ''
    }
  };

  state = this.initialState;

  resetState = () => this.setState(this.initialState);

  onCredentialsChange = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      credentials: {
        ...prevState.credentials,
        [name]: value
      }
    }));
  };

  resetPassword = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        Auth.forgotPassword(this.state.credentials.mail)
          .then(() => {
                localStorage.setItem('mail', this.state.credentials.mail);
                this.props.history.push('?m=passwordReset');
              })
              .catch(({ message }) => {
                ToastContainer.show(message);
              })
      }
    );
  };

  render() {
    const {
      credentials: { mail },
      isLoading
    } = this.state;

    return (
      <Modal
        onAfterOpen={this.resetState}
        isOpen={this.props.isOpen}
        title="Reset your password"
        className={styles.forgotPassword}
        style={{
          content: {
            backgroundImage: `url(${authBackground})`,
            backgroundSize: '90% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left 20%'
          }
        }}
      >
        <Form
          onSubmit={this.resetPassword}
          title={`Enter your e-mail and we'll send you instructions to reset your
        password.`}
        >
          <Form.TextField
            label="EMAIL"
            name="mail"
            type="email"
            onChange={this.onCredentialsChange}
            value={mail}
          />

          <Form.Button
            className={styles.submitButton}
            disabled={!mail || isLoading}
          >
            Reset password
          </Form.Button>
        </Form>
      </Modal>
    );
  }
}

ForgotPasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  lastStep: PropTypes.bool,
  history: PropTypes.object
};

export default withRouter(ForgotPasswordModal);
