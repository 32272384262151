import React from 'react';
import cx from 'classnames';
import snakeIcon21 from '../../../../assets/icons/header-stroke-1.svg';
import snakeIcon2 from '../../../../assets/icons/header-stroke-2.svg';
import styles from './Snakes.scss';

const Snakes = () => (
  <div className={styles.snakes}>
    <img
      src={snakeIcon21}
      alt=""
      className={cx(styles.snakeIcon, styles.snakeIconTop)}
    />
    <img
      src={snakeIcon2}
      alt=""
      className={cx(styles.snakeIcon, styles.snakeIconBottom)}
    />
  </div>
);

export default Snakes;
