import React from 'react';
import PropTypes from 'prop-types';
import MaterialTextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import cx from 'classnames';
import styles from './TextField.scss';

class TextField extends React.Component {
  state = {
    isSelectOpen: false,
    anchorNode: null
  };

  setAnchorNode = node => {
    if (node) {
      this.setState({
        anchorNode: node
      });
    }
  };

  setMenuPosition = node => {
    const rect = this.state.anchorNode.getBoundingClientRect();

    node.style.transform = `translate(${rect.left}px, ${rect.top}px)`;
    node.style.top = 0;
    node.style.left = 0;
    node.style.display = 'block';
  };

  render() {
    const {
      className,
      description,
      labelClassName,
      inputClassName,
      options,
      readOnly,
      ...rest
    } = this.props;

    return (
      <div
        ref={this.setAnchorNode}
        className={cx(styles.wrapper, className, {
          [styles.readOnly]: !!readOnly,
          [styles.isSelect]: !!options,
          [styles.isSelectOpen]: this.state.isSelectOpen
        })}
      >
        <MaterialTextField
          className={styles.textField}
          select={!!options && !!options.length}
          InputLabelProps={{
            FormLabelClasses: {
              root: cx(styles.label, labelClassName),
              focused: styles.labelFocused,
              filled: styles.labelFilled,
              error: styles.labelError
            }
          }}
          InputProps={{
            classes: {
              root: cx(styles.input, inputClassName),
              focused: styles.inputFocused,
              error: styles.inputError
            },
            spellCheck: false
          }}
          SelectProps={{
            className: styles.select,
            MenuProps: {
              onEntered: this.setMenuPosition,
              className: styles.menu,
              classes: {
                paper: styles.menuPaper
              },
              MenuListProps: {
                className: styles.menuList
              },
              transitionDuration: 0,
              onEnter: () => {
                this.setState({ isSelectOpen: true });
              },
              onExit: () => {
                this.setState({ isSelectOpen: false });
              }
            }
          }}
          fullWidth
          {...rest}
        >
          {!!options &&
            options.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                classes={{
                  root: styles.menuItem,
                  selected: styles.menuItemSelected
                }}
              >
                {option.label}
              </MenuItem>
            ))}
        </MaterialTextField>
        <div className={styles.description}>{description}</div>
      </div>
    );
  }
}

TextField.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  options: PropTypes.array,
  readOnly: PropTypes.bool
};

export default TextField;
