import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TextField from '../TextField';
import Button from '../Button';
import styles from './Form.scss';

class Form extends React.Component {
  static TextField = ({
    className,
    labelClassName,
    inputClassName,
    ...rest
  }) => (
    <TextField
      className={cx(className, styles.textField)}
      labelClassName={cx(labelClassName, styles.label)}
      inputClassName={cx(inputClassName, styles.input)}
      {...rest}
    />
  );

  static Button = ({ children, className, ...rest }) => (
    <Button
      className={cx(styles.button, className)}
      type="submit"
      fillWidth
      {...rest}
    >
      {children}
    </Button>
  );

  render() {
    const { children, onSubmit, title } = this.props;

    return (
      <>
        {Boolean(title) && <div className={styles.formTitle}>{title}</div>}
        <form
          className={styles.form}
          onSubmit={e => {
            e.preventDefault();
            onSubmit();
          }}
        >
          {children}
        </form>
      </>
    );
  }
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default Form;
