import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../shared/TextField';
import JSONEditor from '../../../shared/JSONEditor';
import styles from './RequestForm.scss';

class RequestForm extends React.Component {
  renderParamField = param => {
    const label = `${param.name} (${param.schema.type.toUpperCase()})${
      param.required ? '*' : ''
    }`;

    return (
      <TextField
        label={label}
        multiline
        rowsMax="3"
        margin="normal"
        error={param.required && !param.value}
        description={param.description}
        value={param.value}
        onChange={this.changeValue(param)}
      />
    );
  };

  changeValue = param => event => {
    const parameters = this.props.parameters.slice();
    const index = parameters.findIndex(
      item => item.name === param.name && item.in === param.in
    );

    parameters[index].value = event.target.value;
    this.updateParameters(parameters);
  };

  updateParameters = parameters => {
    this.props.onParametersChange(parameters);
  };

  renderParamsBlock = (params, header) => {
    return (
      !!params.length && (
        <>
          <h3>{header}</h3>
          <div className={styles.blockOfFields}>
            {params.map((param, i) => (
              <Fragment key={i}>{this.renderParamField(param)}</Fragment>
            ))}
          </div>
        </>
      )
    );
  };

  render() {
    const { parameters, isBody, body, onBodyChange } = this.props;
    const paramTypes = [
      { key: 'header', name: 'Header params' },
      { key: 'path', name: 'Path params' },
      { key: 'query', name: 'Query params' }
    ];

    return (
      <div className={styles.wrapper}>
        {!!parameters.length && (
          <>
            {paramTypes.map(type => (
              <Fragment key={type.key}>
                {this.renderParamsBlock(
                  parameters
                    .filter(item => item.in === type.key)
                    .map(parameter => {
                      if (
                        !parameter.value &&
                        parameter.schema &&
                        parameter.schema.example
                      ) {
                        parameter.value = parameter.schema.example;
                      }

                      return parameter;
                    }),
                  type.name
                )}
              </Fragment>
            ))}
          </>
        )}

        {isBody && (
          <>
            <h3>Body</h3>
            <div className={styles.body}>
              <JSONEditor value={body} onChange={onBodyChange} />
            </div>
          </>
        )}
      </div>
    );
  }
}

RequestForm.propTypes = {
  parameters: PropTypes.array.isRequired,
  onParametersChange: PropTypes.func,
  isBody: PropTypes.bool.isRequired,
  body: PropTypes.string.isRequired,
  onBodyChange: PropTypes.func
};

export default RequestForm;
