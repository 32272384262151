import React from 'react';
import PropTypes from 'prop-types';
import { _fetch } from '../../../actions/api.config';
import Loader from '../../shared/Loader';
import TextField from '../TextField';
import Button from '../Button';
import styles from './ApplicationIdentityWidget.scss';

class ApplicationIdentityWidget extends React.Component {
  state = {
    isFetching: true,
    isSubmitting: false,
    data: null
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    _fetch('/tpp').then(data => {
      this.setState({ isFetching: false, data });
    });
  };

  submitForm = () => {
    this.setState({ isSubmitting: true }, () => {
      _fetch('/tpp', { method: 'PATCH', body: this.state.data }).then(
        data => {
          this.setState({ isSubmitting: false, data });
        }
      );
    });
  };

  updateFormData = field => ({ target: { value } }) => {
    const newData = Object.assign({}, this.state.data);

    switch (field) {
      case 'clientName':
        newData.clientName = value;
        break;
      case 'redirectUri':
        newData.redirectionUris = [value];
        break;
      case 'contacts':
        newData.contacts = [value];
        break;
      default:
        break;
    }

    this.setState({ data: newData });
  };

  render() {
    const { isFetching, isSubmitting, data } = this.state;

    if (isFetching || !data) {
      return <Loader />;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.columns}>
          <div className={styles.left}>
            <TextField
              className={styles.textField}
              label="Client Name"
              error={!data.clientName}
              value={data.clientName}
              onChange={this.updateFormData('clientName')}
              multiline
            />

            <TextField
              className={styles.textField}
              label="Redirect Uri"
              error={!data.redirectionUris[0]}
              value={data.redirectionUris[0]}
              onChange={this.updateFormData('redirectUri')}
              multiline
            />

            <TextField
              className={styles.textField}
              label="Contacts"
              error={!data.contacts[0]}
              value={data.contacts[0]}
              onChange={this.updateFormData('contacts')}
              multiline
            />
          </div>
          <div className={styles.right}>
            <div className={styles.dataItem}>
              <label>Client Id</label>
              <div className={styles.dataContent}>{data.id}</div>
            </div>
            <div className={styles.dataItem}>
              <label>Client Secret</label>
              <div className={styles.dataContent}>{data.clientSecret}</div>
            </div>
          </div>
        </div>
        <Button
          className={styles.button}
          onClick={this.submitForm}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </div>
    );
  }
}

ApplicationIdentityWidget.propTypes = {
  clientName: PropTypes.string,
  redirectionUris: PropTypes.string,
  contacts: PropTypes.string,
  id: PropTypes.string,
  clientSecret: PropTypes.string
};

export default ApplicationIdentityWidget;
