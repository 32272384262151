import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tab from './Tab';
import styles from './Tabs.scss';

class Tabs extends React.Component {
  state = {
    activeTab: 0
  };

  componentDidMount = () => {
    if (this.props.actions) {
      this.props.actions({
        changeTab: this.changeTab
      });
    }
  };

  getChildrenLabels = () => {
    return React.Children.map(this.props.children, item => {
      return {
        title: item.props.label,
        type: item.props.type
      };
    });
  };

  changeTab = activeTab => {
    this.setState({
      activeTab
    });
  };

  render() {
    const { activeTab } = this.state;
    const { children, listType } = this.props;
    const labels = this.getChildrenLabels();

    return (
      <div className={styles.wrapper}>
        <div className={styles.tabs}>
          <ul className={classnames(styles[`listType-${listType}`])}>
            {labels.map((label, index) => (
              <li
                key={index}
                className={classnames(styles[`type-${label.type}`], {
                  [styles.active]: index === activeTab
                })}
                onClick={() => {
                  this.changeTab(index);
                }}
              >
                {label.title}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.content}>
          {React.Children.toArray(children)[activeTab]}
        </div>
      </div>
    );
  }
}

Tabs.Tab = Tab;

Tabs.propTypes = {
  children: PropTypes.node,
  listType: PropTypes.oneOf(['default', 'balloons']),
  actions: PropTypes.func
};

Tabs.defaultProps = {
  listType: 'default'
};

export default Tabs;
