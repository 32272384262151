import React from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import styles from './JSONEditor.scss';
import 'brace/mode/json';
import './theme';

class JSONEditor extends React.Component {
  configureEditor = editor => {
    editor.getSession().setUseWorker(false); // disable syntax checker
    editor.setShowPrintMargin(false);
    editor.setShowFoldWidgets(false);
    editor.renderer.setScrollMargin(15, 15);
    editor.renderer.setPadding(15);
    editor.renderer.setFadeFoldWidgets(false);
  };

  render() {
    const { height, onChange, value } = this.props;

    return (
      <AceEditor
        className={styles.editor}
        style={{
          width: '100%',
          height: height
        }}
        mode="json"
        theme="luminor"
        value={value}
        onChange={onChange}
        onLoad={this.configureEditor}
        editorProps={{ $blockScrolling: true }}
      />
    );
  }
}

JSONEditor.propTypes = {
  height: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string
};

JSONEditor.defaultProps = {
  height: 300
};

export default JSONEditor;
