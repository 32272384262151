import React from 'react';
import PropTypes from 'prop-types';
import HTTPSnippet from 'httpsnippet';
import Tabs, { Tab } from '../../../shared/Tabs';
import Code from '../../../shared/Code';
import styles from './CodeSnippets.scss';

const CodeSnippets = ({ method, url, queryString, headers, postData }) => {
  const data = {
    url,
    method: method.toUpperCase(),
    ...(queryString && { queryString }),
    ...(headers && { headers }),
    ...(postData && { postData })
  };

  const snippet = new HTTPSnippet(data);
  const renderCode = (code, language) => (
    <div className={styles.code}>
      <Code language={language}>{code}</Code>
    </div>
  );

  return (
    <Tabs>
      <Tab label="cURL">{renderCode(snippet.convert('shell'), 'bash')}</Tab>
      <Tab label="Node.js">
        {renderCode(snippet.convert('node'), 'javascript')}
      </Tab>
      <Tab label="Java">{renderCode(snippet.convert('java'), 'java')}</Tab>
      <Tab label="Python">
        {renderCode(snippet.convert('python'), 'python')}
      </Tab>
      <Tab label="PHP">{renderCode(snippet.convert('php'), 'php')}</Tab>
    </Tabs>
  );
};

CodeSnippets.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  queryString: PropTypes.array,
  headers: PropTypes.array,
  postData: PropTypes.object
};

export default CodeSnippets;
