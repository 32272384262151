import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MediaQuery from 'react-responsive';
import navShapeDesktop from '../../../../assets/icons/header-nav-shape.svg';
import navShapeMobile from '../../../../assets/icons/header-nav-shape-mobile.svg';
import {
  MOBILE_BREAKPOINT,
  DESKTOP_BREAKPOINT
} from '../../../../constants/breakpoints';
import styles from './Burger.scss';

const Burger = ({ onClick, isOpen }) => (
  <div className={styles.burgerHolder}>
    <div
      className={cx(styles.burger, {
        [styles.active]: isOpen
      })}
      onClick={onClick}
    >
      <span />
    </div>
    <MediaQuery maxWidth={MOBILE_BREAKPOINT}>
      <img src={navShapeMobile} alt="" className={styles.burgerShape} />
    </MediaQuery>
    <MediaQuery minWidth={DESKTOP_BREAKPOINT}>
      <img src={navShapeDesktop} alt="" className={styles.burgerShape} />
    </MediaQuery>
  </div>
);

Burger.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default Burger;
