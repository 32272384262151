import React from 'react';
import PropTypes from 'prop-types';
import RCollapsible from 'react-collapsible';
import Text from '../Text';
import styles from './Collapsible.scss';

const Collapsible = ({ list }) => (
  <div className={styles.collapsibleHolder}>
    {list.map((element, i) => (
      <RCollapsible
        trigger={
          <>
            <div className="title">{element.field_title}</div>
            <div className={'arrow'} />
          </>
        }
        transitionTime={220}
        key={i}
      >
        <Text>{element.field_content}</Text>
      </RCollapsible>
    ))}
  </div>
);

Collapsible.propTypes = {
  list: PropTypes.array.isRequired
};

export default Collapsible;
