import React from 'react';
import PropTypes from 'prop-types';
import styles from './MobileTable.scss';
import MobileTableListItem from './MobileTableListItem';

const MobileTable = ({ columns, data }) => {
  const getLeadingColumn = () => {
    const index = columns.findIndex(item => !!item.leading);

    return index !== -1 ? index : 0;
  };
  const leadingColumn = getLeadingColumn();

  return columns.length ? (
    <div className={styles.wrapper}>
      <div className={styles.leadingColumnName}>
        {columns[leadingColumn].name}
      </div>
      {data.map((row, index) => (
        <MobileTableListItem
          key={index}
          row={row}
          columns={columns}
          leadingColumn={leadingColumn}
        />
      ))}
    </div>
  ) : null;
};

MobileTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};

export default MobileTable;
