import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Popover.scss';

class Popover extends React.Component {
  state = {
    isOpen: false
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside.bind(this));
    if (this.props.actions) {
      this.props.actions({
        close: this.closeContent
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mousedown',
      this.handleClickOutside.bind(this)
    );
  }

  handleClickOutside = ({ target }) => {
    if (
      this.buttonRef &&
      this.contentRef &&
      !this.buttonRef.contains(target) &&
      !this.contentRef.contains(target)
    ) {
      this.closeContent();
    }
  };

  toggleContent = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  closeContent = () => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { isOpen } = this.state;
    const { children, content } = this.props;

    return (
      <div className={styles.wrapper}>
        <div
          ref={node => (this.buttonRef = node)}
          className={styles.button}
          onClick={this.toggleContent}
        >
          {children}
        </div>
        <div
          ref={node => (this.contentRef = node)}
          className={classnames(styles.content, { [styles.open]: isOpen })}
        >
          {content}
        </div>
      </div>
    );
  }
}

Popover.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  actions: PropTypes.func
};

export default Popover;
