import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import Container from '../../shared/Container';
import Loader from '../../shared/Loader';
import TagSection from './TagSection';

class OperationsList extends React.Component {
  state = {
    files: [],
    isFetching: true
  };

  componentDidMount() {
    const apiList = this.props;
    // Originally its object, we don't need it, so it will be converted in to array.
    const apiListArr = this.apiListToArr(apiList)

    Promise.all(
        apiListArr.map(item =>
          window.fetch(process.env.REACT_APP_API_DETAIL + item.apiId + '?format=json', {
            headers: {
              'Content-Type': 'application/json',
              Origin: document.URL
            }
          }).then(r => r.json())
      )
    ).then(responses => {
      const files = responses.map((response, index) => ({
        fields: apiListArr[index],
        data: this.groupOperationsByTags(response)
      }));

      this.setState({
        files,
        isFetching: false
      });
    });
  }

  render() {
    const { files, isFetching } = this.state;

    if (isFetching) {
      return <Loader pageLoader />;
    }

    if (!files.length) {
      return <div />;
    }

    return (
      <Container>
        {files.map((file, index) => (
          <Fragment key={index}>{this.renderFileTags(file)}</Fragment>
        ))}
      </Container>
    );
  }

  groupOperationsByTags = data => {
    const tags = {};
    const paths = Object.keys(data.paths);

    paths.forEach(path => {
      const methods = Object.keys(data.paths[path]);

      methods.forEach(method => {
        const operation = data.paths[path][method];

        if (operation.tags) {
          operation.tags.forEach(tag => {
            if (!tags[tag]) {
              tags[tag] = {
                name: tag,
                description: this.getTagDescription(data, tag),
                operations: []
              };
            }
            tags[tag].operations.push({
              ...operation,
              path,
              method
            });
          });
        } else {
          tags[
            '_' +
              Math.random()
                .toString(36)
                .substr(2, 9)
          ] = {
            name: '',
            description: '',
            operations: [{ ...operation, path, method }]
          };
        }
      });
    });
    return tags;
  };

  /**
   * Convert response from API with listing to a simple array.
   *
   * @param apiList
   * @returns {Array}
   */
  apiListToArr (apiList) {
    const returnArr = []

    for (const i in apiList) {
      if (!apiList[i].apiId) {
        continue;
      }
      returnArr.push(apiList[i])
    }

    return returnArr
  }

  getTagDescription = (data, key) => {
    if (!data.tags) {
      return '';
    }
    const tag = data.tags.find(item => item.name === key);

    return tag && tag.description ? tag.description : '';
  };

  renderFileTags = file => {
    return (
      <>
        {Object.keys(file.data).map(key => (
          <TagSection
            key={key}
            tag={file.data[key]}
            fields={file.fields}
          />
        ))}
      </>
    );
  };

  // componentDidMount() {
  //   const { field_paragraphs } = this.props;
  //
  //   Promise.all(
  //     field_paragraphs.map(item =>
  //       window.fetch(item.field_swagger_file).then(r => r.json())
  //     )
  //   ).then(responses => {
  //     const files = responses.map((response, index) => ({
  //       drupalFields: field_paragraphs[index],
  //       data: this.groupOperationsByTags(response)
  //     }));
  //
  //     this.setState({
  //       files,
  //       isFetching: false
  //     });
  //   });
  // }
  //
  // groupOperationsByTags = data => {
  //   const tags = {};
  //   const paths = Object.keys(data.paths);
  //
  //   paths.forEach(path => {
  //     const methods = Object.keys(data.paths[path]);
  //
  //     methods.forEach(method => {
  //       const operation = data.paths[path][method];
  //
  //       if (operation.tags) {
  //         operation.tags.forEach(tag => {
  //           if (!tags[tag]) {
  //             tags[tag] = {
  //               name: tag,
  //               description: this.getTagDescription(data, tag),
  //               operations: []
  //             };
  //           }
  //           tags[tag].operations.push({
  //             ...operation,
  //             path,
  //             method
  //           });
  //         });
  //       } else {
  //         tags[
  //           '_' +
  //             Math.random()
  //               .toString(36)
  //               .substr(2, 9)
  //         ] = {
  //           name: '',
  //           description: '',
  //           operations: [{ ...operation, path, method }]
  //         };
  //       }
  //     });
  //   });
  //
  //   return tags;
  // };
  //
  // getTagDescription = (data, key) => {
  //   if (!data.tags) {
  //     return '';
  //   }
  //   const tag = data.tags.find(item => item.name === key);
  //
  //   return tag && tag.description ? tag.description : '';
  // };
  //
  // renderFileTags = file => {
  //   return (
  //     <>
  //       {Object.keys(file.data).map(key => (
  //         <TagSection
  //           key={key}
  //           tag={file.data[key]}
  //           drupalFields={file.drupalFields}
  //         />
  //       ))}
  //     </>
  //   );
  // };
  //
  // render() {
  //   const { files, isFetching } = this.state;
  //
  //   if (isFetching) {
  //     return <Loader pageLoader />;
  //   }
  //
  //   if (!files.length) {
  //     return <div />;
  //   }
  //
  //   return (
  //     <Container>
  //       {files.map((file, index) => (
  //         <Fragment key={index}>{this.renderFileTags(file)}</Fragment>
  //       ))}
  //     </Container>
  //   );
  // }
}

// OperationsList.propTypes = {
//   field_paragraphs: PropTypes.array.isRequired
// };

export default OperationsList;
