import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import animateScrollTo from 'animated-scroll-to';
import Hero from './Hero';
import FeaturesList from './FeaturesList';
import Snakes from './Snakes';
import Footer from './Footer';
import styles from './HomePage.scss';

class HomePage extends React.Component {
  componentDidMount() {
    const body = document.body;

    body.classList.add(styles.blueTheme);
  }

  componentWillUnmount() {
    const body = document.body;

    body.classList.remove(styles.blueTheme);
  }

  render() {
    const { field_paragraphs } = this.props;
    const hero = field_paragraphs.find(({ type }) => type === 'hero') || {};
    const featuresList = field_paragraphs.filter(
      ({ type }) => type === 'text_with_image'
    );
    const footer = field_paragraphs.find(({ type }) => type === 'footer') || {};

    return (
      <Fragment>
        <Snakes />
        {Boolean(Object.keys(hero).length) && (
          <Hero
            title={hero.field_title}
            subTitle={hero.field_sub_title}
            image={hero.field_image}
            onScrollDown={() => {
              animateScrollTo(window.innerHeight, {
                speed: 300,
                element: document.body
              });
            }}
          />
        )}
        {Boolean(featuresList.length) && <FeaturesList list={featuresList} />}
        {Boolean(Object.keys(footer).length) && (
          <Footer elements={footer.field_footer_elements} />
        )}
      </Fragment>
    );
  }
}

HomePage.propTypes = {
  field_paragraphs: PropTypes.array.isRequired
};

export default HomePage;
