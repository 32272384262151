import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import cx from 'classnames';
import userIcon from '../../../../assets/icons/user.svg';
import navShapeDesktop from '../../../../assets/icons/header-nav-shape.svg';
import navShapeMobile from '../../../../assets/icons/header-nav-shape-mobile.svg';
import {
  MOBILE_BREAKPOINT,
  DESKTOP_BREAKPOINT
} from '../../../../constants/breakpoints';
import styles from './Avatar.scss';

const Avatar = ({ onClick, isOpen, isAuthorized, userName }) => {
  return (
    <div
      className={cx(styles.avatarHolder, {
        [styles.open]: isOpen,
        [styles.authorized]: isAuthorized
      })}
      onClick={onClick}
    >
      <div className={styles.avatar}>
        {isAuthorized ? (
          <div>{userName.substring(0, 2)}</div>
        ) : (
          <img src={userIcon} alt="" />
        )}
      </div>

      <MediaQuery maxWidth={MOBILE_BREAKPOINT}>
        <img src={navShapeMobile} alt="" className={styles.shape} />
      </MediaQuery>
      <MediaQuery minWidth={DESKTOP_BREAKPOINT}>
        <img src={navShapeDesktop} alt="" className={styles.shape} />
      </MediaQuery>
    </div>
  );
};

Avatar.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  userName: PropTypes.string
};

export default Avatar;
