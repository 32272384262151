import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { DESKTOP_BREAKPOINT } from '../../../constants/breakpoints';
import logo from '../../../assets/icons/Luminor-logo.svg';
import { OverlaysConsumer } from '../../App/Overlays.context';
import { AuthConsumer } from '../../App/Auth.context';
import Burger from './Burger';
import Avatar from './Avatar';
import StickyAuthButtons from './StickyAuthButtons';
import MainMenu from './MainMenu';
import UserMenu from './UserMenu';
import styles from './Header.scss';

const Header = ({ navLinks, userMenu, history }) => (
  <OverlaysConsumer>
    {({ toggleOverlay, isOverlayOpen }) => (
      <>
        <header className={styles.header}>
          <div className={styles.top}>
            <AuthConsumer>
              {({ isAuthorized, userName }) => (
                <Avatar
                  isAuthorized={isAuthorized}
                  userName={userName}
                  onClick={() => {
                    toggleOverlay(isAuthorized ? 'userMenu' : 'login');
                  }}
                  isOpen={
                    isOverlayOpen('login') ||
                    isOverlayOpen('register') ||
                    isOverlayOpen('userMenu')
                  }
                />
              )}
            </AuthConsumer>
          </div>
          <div className={styles.middle}>
            <Burger
              isOpen={isOverlayOpen('menu')}
              onClick={() => toggleOverlay('menu')}
            />
          </div>
          <div className={styles.bottom}>
            <div className={styles.logo} onClick={() => history.push('/')}>
              <img src={logo} alt="" />
            </div>
          </div>
        </header>
        <AuthConsumer>
          {({ isAuthorized, userName, onLogOut }) => (
            <>
              <MainMenu
                isAuthorized={isAuthorized}
                userName={userName}
                isOpen={isOverlayOpen('menu')}
                navLinks={navLinks}
                onClose={() => toggleOverlay('menu')}
                onLogOut={onLogOut}
              />
              {!isAuthorized && (
                <MediaQuery minWidth={DESKTOP_BREAKPOINT}>
                  <StickyAuthButtons toggleOverlay={toggleOverlay} />
                </MediaQuery>
              )}
              <UserMenu
                isAuthorized={isAuthorized}
                userName={userName}
                isOpen={isOverlayOpen('userMenu')}
                navLinks={userMenu}
                onClose={() => toggleOverlay('userMenu')}
                onLogOut={onLogOut}
              />
            </>
          )}
        </AuthConsumer>
      </>
    )}
  </OverlaysConsumer>
);

Header.propTypes = {
  navLinks: PropTypes.array.isRequired,
  userMenu: PropTypes.array,
  history: PropTypes.object.isRequired
};

export default withRouter(Header);
